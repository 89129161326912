import React from "react";
import {useDispatch} from "react-redux";
import {Box, Typography, Grid, Paper, useMediaQuery} from "@material-ui/core";
import {EmailOutlined, LockOutlined} from "@material-ui/icons";
import {Link} from "react-router-dom";

import {useTheme} from "@material-ui/core/styles";

import {Formik, Form} from "formik";

import PrimaryButton from "../../components/FormComponents/PrimaryButton";
import TextField from "../../components/FormComponents/TextField";
import Checkbox from "../../components/FormComponents/CheckBox";

import {login} from "../../store/actions/profile";
import {LoginSchema} from "../../components/Forms/LoginForm/validation";

import * as path from "../../constants/routes";
import Header from "../../components/HomePage/Header";

const Login = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();

  const handleLogin = async (values, {setFieldError}) => {
    const moveTo = (user, payoutsEnabled = true) => {
      // Send the user either to their dashboard or profile, depending on if they're eligible for
      // deals. Profile is where user sets up their account to be eligible for deals.

      const showProfile = (
        (
          (!user.utility_api_key && !user.meter_uid) ||
          (!user.payment_api_key && !user.discount_code) ||
          !payoutsEnabled
        ) &&
        !user.allow_invoices
      )
      const redirectPath = showProfile ? path.PROFILE : path.DASHBOARD

      props.history.push(redirectPath)
    };
    dispatch(login(values, setFieldError, moveTo));
  };

  return (
    <>
      <Header></Header>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="min-h-screen bg-ternary"
      >
        <Grid
          item
          style={{minWidth: isMobile ? "100%" : "40%"}}
          className="my-8"
        >
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={LoginSchema}
            onSubmit={handleLogin}
          >
            {({values: {email, password}, touched, errors, setFieldValue}) => (
              <Paper
                elevation={3}
                className={
                  isMobile ? "py-20 px-4 rounded-2xl" : "py-20 px-16 rounded-2xl"
                }
              >
                <Form>
                  <Grid container justifyContent="flex-start" direction="column">
                    <Typography
                      variant="h4"
                      className="font-jost text-primary text-left font-bold"
                      component="div"
                    >
                      SEP
                    </Typography>
                    <Typography
                      variant="h3"
                      className="font-jost text-heading font-bold text-left pb-1 mt-6"
                      component="div"
                    >
                      Login
                    </Typography>
                    <TextField
                      id="email"
                      name="email"
                      helperText={touched.email ? errors.email : ""}
                      error={touched.email && Boolean(errors.email)}
                      value={email}
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                      placeholder="Email"
                      type="email"
                      className="mt-6"
                      icon={<EmailOutlined/>}
                    />
                    <TextField
                      id="password"
                      name="password"
                      helperText={touched.password ? errors.password : ""}
                      error={touched.password && Boolean(errors.password)}
                      value={password}
                      onChange={(e) => {
                        setFieldValue("password", e.target.value);
                      }}
                      placeholder="Password"
                      type="password"
                      className="mt-6"
                      autoComplete={"off"}
                      icon={<LockOutlined/>}
                    />
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className="mt-6"
                    >
                      <Checkbox small label="Keep me logged in"/>
                      <Typography variant="subtitle2" align="right" component="span">
                        <Link
                          to={path.FORGOT_PASSWORD}
                          className="no-underline text-subheading font-open-sans font-semibold"
                        >
                          Forgot Password?
                        </Link>
                      </Typography>
                    </Grid>
                    <PrimaryButton name="Login" className="mt-6" type="submit"></PrimaryButton>
                    <Box className="mt-6">
                      <Typography
                        variant="h6"
                        className="font-open-sans text-gray-500"
                        component="span"
                      >
                        Dont have an account?
                      </Typography>{" "}
                      <Typography variant="h6" component="span">
                        <Link
                          to={path.SIGN_UP}
                          className="no-underline text-heading font-open-sans"
                        >
                          Register
                        </Link>
                      </Typography>
                    </Box>
                  </Grid>
                </Form>
              </Paper>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
