import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 22,
    left: "calc(-50% + 40px)",
    right: "calc(50% + 40px)",
    [theme.breakpoints.down(600)]: {
      top: 14,
      left: "calc(-60% + 40px)",
      right: "calc(40% + 40px)",
    },
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: "#bdbdbd",
    borderRadius: 1,
  },
}))(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    color: "#b2ddcd",
    width: 50,
    height: 50,
    border: "1px solid #b2ddcd",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(600)]: {
      width: 30,
      height: 30,
    },
  },
  completed: {
    color: "#6aac91",
    border: "1px solid #6aac91",
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.completed]: completed,
      })}
    >
      {props.icon}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "16px",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
    },
  },
  stepper: {
    padding: "16px 0",
  },
  label: {
    [theme.breakpoints.down(600)]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down(410)]: {
      fontSize: "10px",
    },
  },
  item: {
    "& .MuiStep-alternativeLabel": {
      minWidth: "68px",
    },
  },
}));

function getSteps(profile) {
  const isCompletedSI =
    profile.username &&
    profile.address &&
    profile.email &&
    profile.seller_code &&
    profile.utility_zone
      ? true
      : false;
  const isCompletedUtility = profile.utility_api_key ? true : false;
  const isCompletedPayment = profile.payment_api_key || profile.discount_code || profile.allow_invoices ? true : false;
  const isCompletedSP = profile.utility_api_key ? true : false;
  const isCompletedLive =
    isCompletedSI && isCompletedUtility && isCompletedPayment && isCompletedSP
      ? true
      : false;
  let steps = [
    {
      completed: isCompletedSI,
      label: `${profile.customer_type === "SELLER" ? "Clean Energy Producer" : "Clean Energy Consumer"} Information`,
      icon: <AssignmentIndIcon />,
    },
    {
      completed: isCompletedUtility,
      label: "Utility Information",
      icon: <SettingsApplicationsIcon />,
    },
    {
      completed: isCompletedPayment,
      label: "Payment Information",
      icon: <MonetizationOnIcon />,
    },
    { completed: isCompletedLive, label: "Live!", icon: <DoneOutlineIcon /> },
  ];
  return steps;
}

export default function UserCompletedSteps() {
  const profile = useSelector((state) => state.profile.profile);
  const classes = useStyles();
  const steps = getSteps(profile);

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        connector={<ColorlibConnector />}
        classes={{ root: classes.stepper, alternativeLabel: classes.item }}
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              completed={step.completed}
              active={false}
              icon={step.icon}
              classes={{ label: classes.label, completed: classes.label }}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
